import React from "react";

//Here I need to change the theme for messageTeam -> look through the docs
import { Channel, useChatContext, MessageSimple } from "stream-chat-react";

import { ChannelInner, CreateChannel, EditChannel } from "./";

const ChannelContainer = ({
  isCreating,
  setIsCreating,
  isEditing,
  setIsEditing,
  createType,
}) => {
  

  //check if channel exist already
  if (isCreating) {
    return (
      <div className="channel__container">
        <CreateChannel createType={createType} setIsCreating={setIsCreating} />
      </div>
    );
  }

  if (isEditing) {
    return (
      <div className="channel__container">
        <EditChannel setIsEditing={setIsEditing} />
      </div>
    );
  }

  const EmptyState = () => (
    <div className="channel-empty__container">
      <p className="channel-empty__first">This is the beginning of your chat history</p>
      <p className="channel-empty__second">Send messages, attachments, links, emojis, and more!</p>
    </div>
  )

  return <div className="channel__container">
    <Channel EmptyStateIndicator={EmptyState}
    // Message={(messageProps, i) => <MessageSimple key={i} {...messageProps} />}
    >
      <ChannelInner setIsEditing={setIsEditing} />
    </Channel>
  </div>;
};

export default ChannelContainer;
